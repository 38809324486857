var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"edit-workers-disease","size":"lg","title":_vm.type==='add' ? 'Thêm bệnh nghề nghiệp': 'Chỉnh sửa bệnh nghề nghiệp',"cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu","centered":"","no-close-on-backdrop":""},on:{"ok":_vm.handleCreateWorkerDisease,"hidden":_vm.resetState}},[_c('validation-observer',{ref:"rule"},[_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('b-form-group',{staticClass:"mt-1",attrs:{"label-for":"user"}},[_c('label',{attrs:{"for":"user"}},[_vm._v("Người lao động "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":_vm.worker},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (Value) { return Value.id; },"label":"name","options":_vm.dataAllWorker || [],"placeholder":'Lựa chọn người lao động'},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}},{key:"selected-option-container",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"vs__selected"},[_vm._v(" "+_vm._s(option.name)+" ")])]}},{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataCreateWorkerDisease.workerId),callback:function ($$v) {_vm.$set(_vm.dataCreateWorkerDisease, "workerId", $$v)},expression:"dataCreateWorkerDisease.workerId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('b-form-group',{staticClass:"mt-1",attrs:{"label-for":"classfication"}},[_c('label',{attrs:{"for":"user"}},[_vm._v("Tên bệnh nghề nghiệp "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":_vm.customDisease},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"reduce":function (Value) { return Value.id; },"label":"name","options":_vm.dataComboboxWorkerDisease || [],"placeholder":'Lựa chọn tên bệnh nghề nghiệp'},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}},{key:"selected-option-container",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"vs__selected"},[_vm._v(" "+_vm._s(option.name)+" ")])]}},{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataCreateWorkerDisease.occupationalDiseaseTypeId),callback:function ($$v) {_vm.$set(_vm.dataCreateWorkerDisease, "occupationalDiseaseTypeId", $$v)},expression:"dataCreateWorkerDisease.occupationalDiseaseTypeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('b-form-group',{staticClass:"mt-1",attrs:{"label-for":"classfication"}},[_c('label',{attrs:{"for":"user"}},[_vm._v("Ngày khai báo "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":_vm.customDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"disabledInput":true},model:{value:(_vm.dataCreateWorkerDisease.date),callback:function ($$v) {_vm.$set(_vm.dataCreateWorkerDisease, "date", $$v)},expression:"dataCreateWorkerDisease.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Số ngày nghỉ phép "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":_vm.absent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataCreateWorkerDisease.absent),callback:function ($$v) {_vm.$set(_vm.dataCreateWorkerDisease, "absent", $$v)},expression:"dataCreateWorkerDisease.absent"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Tổng chi phí "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":_vm.cost},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataCreateWorkerDisease.cost),callback:function ($$v) {_vm.$set(_vm.dataCreateWorkerDisease, "cost", $$v)},expression:"dataCreateWorkerDisease.cost"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('b-form-checkbox',{staticClass:"mt-3",attrs:{"id":"checkbox-1","name":"checkbox-1"},model:{value:(_vm.dataCreateWorkerDisease.isRetire),callback:function ($$v) {_vm.$set(_vm.dataCreateWorkerDisease, "isRetire", $$v)},expression:"dataCreateWorkerDisease.isRetire"}},[_vm._v(" Có nghỉ hưu trước tuổi ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }