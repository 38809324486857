<template>
  <b-modal
    id="edit-workers-disease"
    size="lg"
    :title="type==='add' ? 'Thêm bệnh nghề nghiệp': 'Chỉnh sửa bệnh nghề nghiệp'"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateWorkerDisease"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group
            class="mt-1"
            label-for="user"
          >
            <label for="user">Người lao động <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="worker"
            >
              <v-select
                v-model="dataCreateWorkerDisease.workerId"
                :reduce="Value => Value.id"
                label="name"
                :options="dataAllWorker || []"
                :placeholder="'Lựa chọn người lao động'"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  {{ option.name }}
                </template>
                <template #selected-option-container="{ option }">
                  <div class="vs__selected">
                    {{ option.name }}
                  </div>
                </template>
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group
            class="mt-1"
            label-for="classfication"
          >
            <label for="user">Tên bệnh nghề nghiệp <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="customDisease"
            >
              <v-select
                v-model="dataCreateWorkerDisease.occupationalDiseaseTypeId"
                :class="errors.length > 0 ? 'is-invalid':null"
                :reduce="Value => Value.id"
                label="name"
                :options="dataComboboxWorkerDisease || []"
                :placeholder="'Lựa chọn tên bệnh nghề nghiệp'"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  {{ option.name }}
                </template>
                <template #selected-option-container="{ option }">
                  <div class="vs__selected">
                    {{ option.name }}
                  </div>
                </template>
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group
            class="mt-1"
            label-for="classfication"
          >
            <label for="user">Ngày khai báo <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="customDate"
            >
              <date-time-picker
                v-model="dataCreateWorkerDisease.date"
                :disabledInput="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Số ngày nghỉ phép <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="absent"
            >
              <b-form-input
                v-model="dataCreateWorkerDisease.absent"
                type="number"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Tổng chi phí <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="cost"
            >
              <b-form-input
                v-model="dataCreateWorkerDisease.cost"
                type="number"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-checkbox
            id="checkbox-1"
            v-model="dataCreateWorkerDisease.isRetire"
            class="mt-3"
            name="checkbox-1"
          >
            Có nghỉ hưu trước tuổi
          </b-form-checkbox>
        </b-col>
      </b-row>

    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BFormGroup,
  BModal,
  BFormInput,
  BCol,
  BRow,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCol,
    BRow,
    DateTimePicker,
    BFormCheckbox,
  },
  props: {
    dataDetalWorkerDisease: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataCreateWorkerDisease: {
        workerId: '',
        occupationalDiseaseTypeId: '',
        absent: '',
        isRetire: false,
        cost: '',
        date: '',
      },
      customDisease: {
        required: 'Tên bệnh nghề nghiệp là bắt buộc',
      },
      cost: {
        required: 'Tổng chi phí là bắt buộc',
      },
      absent: {
        required: 'Số ngày nghỉ phép là bắt buộc',
      },
      worker: {
        required: 'Người lao động là bắt buộc',
      },
      customDate: {
        required: 'Ngày khai báo là bắt buộc',
      },
    }
  },
  computed: {
    ...mapGetters('workerPermit', ['dataAllWorker']),
    ...mapGetters('workerDisease', ['dataComboboxWorkerDisease']),
  },
  watch: {
    dataDetalWorkerDisease(val) {
      if (val && this.type === 'edit') {
        this.dataCreateWorkerDisease = {
          workerId: this.dataDetalWorkerDisease.workerId,
          occupationalDiseaseTypeId: this.dataDetalWorkerDisease.occupationalDiseaseTypeId,
          absent: this.dataDetalWorkerDisease.absent,
          isRetire: this.dataDetalWorkerDisease.isRetire,
          cost: this.dataDetalWorkerDisease.cost,
          date: this.dataDetalWorkerDisease.date,
        }
      }
    },
  },
  mounted() {
    this.fetchDataComboboxAllWorker()
    this.fetchDataComboboxWorkerDisease()
  },
  methods: {

    ...mapActions('workerPermit', ['fetchDataComboboxAllWorker']),
    ...mapActions('workerDisease', ['fetchDataComboboxWorkerDisease']),

    handleCreateWorkerDisease(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateWorkerDisease', this.dataCreateWorkerDisease)
        }
      })
    },
    resetState() {
      this.dataCreateWorkerDisease = {
        workerId: '',
        occupationalDiseaseTypeId: '',
        absent: '',
        isRetire: false,
        cost: '',
        date: '',
      }
    },
  },
}
</script>
